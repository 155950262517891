import React from "react";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";

const photoGalleryCard = ({ image }) => {
    return (
        <div className="col-lg-4 col-sm-6">
            <div className="gallery-item">
                <SimpleReactLightbox>
                    <SRLWrapper>
                        <img src={image} height="200" alt="" />
                    </SRLWrapper>
                </SimpleReactLightbox>
            </div>
        </div>
    );
};

export default photoGalleryCard;
