import React from "react";
import axios from "axios";
import PhotoGalleryCard from "./photo-gallery-card";
import Loader from "../shimmer-loader";
import NoRecords from "../no-records";

var Api_url = process.env.GATSBY_API_URL;
console.log({ Api_url });

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
    },
};

class PhotoGalleryPage extends React.Component {

    constructor() {
        super();
        this.state = {
            Records: [],
            LoadingFlag: false,
            TotalRecords: 0,
        }
    };

    getAllRecords = async () => {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/app-settings-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 1,
            AppName: "foundation_website",
            SettingsName: "gallery",
        };
        this.setState({ LoadingFlag: true });
        console.log({ postData });
        // this.setState({ isLoading: true });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var records = res.data.records;
                console.log({ records });
                var result = records.SettingsValues;
                var n1 = result.length;
                console.log("n1=" + n1);

                this.setState({ LoadingFlag: false, Records: result, TotalRecords: n1 });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    componentDidMount() {
        console.log("componentDidMount called ");
        // window.location.replace('https://www.google.com')
        this.getAllRecords();
    }

    render() {
        return (<section className="gallery-area2">
            <div className="container">
                <div className="row">
                    {this.state.LoadingFlag ? <Loader /> : ""}
                    {this.state.TotalRecords > 0 ?
                        this.state.Records.map(item => (
                            <PhotoGalleryCard image={item.FilePath} />
                        ))
                        :
                        <NoRecords />
                    }
                </div>
            </div>
        </section>
        );
    }
};

export default PhotoGalleryPage;
